<script>
import VShowInvoice from "@/components/Momentum/Invoices/VShowInvoice";
import InvoiceService from "@/services/InvoiceService";
import VFilters from "@/components/Search/VFilters";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VInvoicing",
    mixins: [AppCfg],
    components: { VFilters, VShowInvoice },
    data() {
        return {
            job_id: null, // job_id bg job
            job_loading: false, // bg job loading
            loading: false,
            invoices: [], // main invoices
            selectedInvoices: [], // checkbox containing selections
            showResult: false, // final result after processing
            context: null,
        };
    },
    computed: {
        selectAll: {
            get: function () {
                return this.invoices
                    ? this.selectedInvoices.length == this.invoices.length
                    : false;
            },

            set: function (v) {
                let selected = [];

                if (v) {
                    this.invoices.forEach(function (v, index) {
                        selected.push(index);
                    });
                }

                this.selectedInvoices = selected;
            },
        },
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);
        // await this.fetchFilters();
        this.apiPaginator.sort = "Invoices.created";
        this.apiPaginator.direction = "asc";
        // this.apiFilterView = this.dateRanges[1].value;
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        /**
         * On layout/terms change from invoice viewer -> update invoice obj. here.
         */
        updateInvoice(update) {
            let key = this.invoices.findIndex((inv) => {
                return (inv.id = update.invoice_id);
            });

            this.invoices[key]["ec_req_layout"] = update.ec_req_layout;
            this.invoices[key]["ec_req_terms"] = update.ec_req_terms;
        },
        showInvoice(invoice) {
            this.context = invoice;
        },
        /**
         * Fetch invoices
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["status"] = 2; // ready top invoice
                params["includes"] = "project"; // sideload project
                const r = await InvoiceService.GET_projectInvoicing(params);
                // These two must be reset
                this.invoices = [];
                this.selectedInvoices = [];
                r.data.data.forEach((obj) => {
                    this.invoices.push({ ...obj, visible: false });
                });

                //this.$emit("updateCounts", this.counts);
            } catch (e) {
                console.log("Exception", e);
            }

            this.loading = false;
        },
        /**
         * Send invoices
         */
        async sendInvoices(singleInvoice, deal = "") {
            try {
                this.job_loading = true;
                let copy = [];

                console.log("single", singleInvoice, typeof singleInvoice);
                let params = {};

                if (singleInvoice === null) {
                    // Sending multiple invoices
                    console.log("Sending selectedInvoices");
                    this.selectedInvoices.forEach((key) => {
                        copy.push(this.invoices[key].id);
                    });
                } else {
                    // Sending a single invoice
                    console.log("Sending singleInvoice");
                    copy.push(singleInvoice);
                    params["deal"] = deal;
                }

                const r = await InvoiceService.POST_invoiceProjects(
                    copy,
                    params
                );
                this.job_id = r.data.data.job_id;
            } catch (e) {
                this.job_loading = false;
                console.log("Exception", e);
            }
        },
        /**
         * Ready
         */
        async doneInvoices(result) {
            this.job_loading = false;
            this.job_id = null;
            console.log(result);
            this.result = result;
            this.showResult = true;
            //this.fetchData();
        },
        async closeInvoices() {
            this.showResult = false;
            await this.fetchData();
        },
        // Notice tmp format -> deal
        async sendSingleInvoice({ invoice_id, deal }) {
            this.context = null;
            this.sendInvoices(invoice_id, deal);
            console.log("Send single ", invoice_id);
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag />
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="$t('invoice.placeholder_search')"
                    >
                        <template
                            slot="left"
                            v-if="!loading && invoices.length"
                        >
                            <base-button
                                type="secondary"
                                @click="sendInvoices(null)"
                                v-if="ACL_RW"
                                ><i class="far fa-file-export"></i>
                                {{ $t("invoices.send_selected") }}</base-button
                            >
                        </template>
                        <template slot="search">
                            <FormulateInput
                                v-model="apiSearchFilter.customer"
                                type="fsearch"
                                :placeholder="$t('invoices.search')"
                            />
                        </template>
                    </v-filters>
                </div>
            </div>

            <div class="row card-wrapper">
                <div class="col-12">

                    <v-loader :loading="loading" v-if="loading" />

                    <card body-classes="px-0" v-else>
                        <template v-if="invoices.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><input
                                                    type="checkbox"
                                                    v-model="selectAll"
                                            /></v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoice_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_date_short"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th>
                                                {{
                                                    $t(
                                                        "invoices.invoice_setup_veryshort"
                                                    )
                                                }}
                                            </v-th>

                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Customers.name"
                                                    >{{
                                                        $t("invoices.customer")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Projects.id"
                                                    >{{
                                                        $t("invoices.case_no")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Projects.title"
                                                    >{{
                                                        $t(
                                                            "invoices.case_title"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="invoice_header"
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_header"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    name="Projects.user_id"
                                                    >{{
                                                        $t(
                                                            "invoices.responsible"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>

                                            <v-th class="text-right">
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="total_net_amount"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.total_net_amount"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th class="text-center"> </v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <template
                                            v-for="(invoice, index) in invoices"
                                        >
                                            <v-tr :key="index">
                                                <v-td
                                                    ><input
                                                        type="checkbox"
                                                        :value="index"
                                                        v-model="
                                                            selectedInvoices
                                                        "
                                                /></v-td>
                                                <v-td>{{
                                                    $d(
                                                        new Date(
                                                            invoice.created
                                                        ),
                                                        "short"
                                                    )
                                                }}</v-td>
                                                <v-td>
                                                    {{
                                                        appCfgRead(
                                                            "Projects.invoice_setup"
                                                        )[
                                                            invoice.project
                                                                .invoice_setup
                                                        ]
                                                    }}
                                                </v-td>

                                                <v-td
                                                    >{{ invoice.customer.name }}
                                                </v-td>
                                                <v-td>{{
                                                    invoice.project.id
                                                }}</v-td>
                                                <v-td>{{
                                                    invoice.project.title
                                                }}</v-td>
                                                <v-td
                                                    ><v-empty
                                                        :text="
                                                            invoice.invoice_header
                                                        "
                                                /></v-td>
                                                <v-td>
                                                    {{
                                                        invoice.project
                                                            .user_name
                                                    }}
                                                    <div
                                                        class="small mt-2 text-bold bg-info text-white p-2"
                                                        style="
                                                            border-radius: 6px;
                                                        "
                                                        v-if="
                                                            invoice.bkeeper_message
                                                        "
                                                    >
                                                        {{
                                                            invoice.bkeeper_message
                                                        }}
                                                    </div>
                                                </v-td>

                                                <v-td class="text-right">
                                                    {{
                                                        $n(
                                                            invoice.total_net_amount,
                                                            {
                                                                style: "currency",
                                                                currency:
                                                                    invoice.currency,
                                                                currencyDisplay:
                                                                    "code",
                                                            }
                                                        )
                                                    }}
                                                </v-td>
                                                <v-td class="text-center">
                                                    <i
                                                        class="far fa-eye fa-lg m-click"
                                                        @click="
                                                            showInvoice(invoice)
                                                        "
                                                    ></i>
                                                </v-td>
                                            </v-tr>
                                        </template>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>
                        <div class="px-mg" v-else>
                            <div class="nice-info">
                                {{ $t("invoice.no_invoices_to_send") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <v-queue
                :name="$t('invoices.creating_invoices')"
                :job_id="job_id"
                :loading="job_loading"
                @task-ready="doneInvoices"
            />

            <portal to="modals">
                <v-show-invoice
                    v-if="context"
                    :invoice="context"
                    :project="true"
                    @close="context = null"
                    @send="sendSingleInvoice"
                    @update="updateInvoice"
                />
            </portal>

            <portal to="modals" v-if="showResult">
                <modal
                    size="xl"
                    :show="true"
                    :showClose="true"
                    class="invoice-result-modal"
                    @close="closeInvoices"
                >
                    <template slot="header">
                        {{ $t("invoices.result") }}
                    </template>

                    <template slot="default">
                        <v-table class="table table-sm table-mg">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("invoices.customer") }}</v-th>
                                    <v-th>{{
                                        $t("invoices.invoice_date")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoices.amount")
                                    }}</v-th>
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr
                                    v-for="(r, index) in result.result"
                                    :key="index"
                                >
                                    <v-td>
                                        {{ r.invoice.debtor.name }}
                                        <div
                                            class="alert alert-danger alert-outline mt-3"
                                            v-if="r.ec_result.status == false"
                                        >
                                            {{
                                                $t("invoice.error_introduction")
                                            }}
                                            <div
                                                style="font-style: italic"
                                                class="mt-2"
                                            >
                                                {{ r.ec_result.error }}
                                            </div>
                                        </div>
                                    </v-td>
                                    <v-td>
                                        <template v-if="r.ec_result.status">
                                            {{
                                                $d(
                                                    new Date(
                                                        r.entity.invoice_date
                                                    ),
                                                    "short"
                                                )
                                            }}</template
                                        >
                                        <template v-else>---</template>
                                    </v-td>
                                    <v-td class="text-right">
                                        {{
                                            $n(
                                                r.invoice.payload
                                                    .total_net_amount,
                                                {
                                                    style: "currency",
                                                    currency:
                                                        r.invoice.payload
                                                            .currency,
                                                    currencyDisplay: "code",
                                                }
                                            )
                                        }}
                                    </v-td>
                                    <v-td class="text-right">
                                        <i
                                            class="far"
                                            :class="{
                                                'fa-check': r.ec_result.status,
                                                'fa-times': !r.ec_result.status,
                                            }"
                                        ></i>
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                    </template>
                </modal>
            </portal>
        </div>
    </div>
</template>

<style>
</style>
