<script>
import InvoiceService from "@/services/InvoiceService";
import VFilters from "@/components/Search/VFilters";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VInvoicing",
    mixins: [MGConsts],
    components: { VFilters },
    data() {
        return {
            dateRanges: [],
            job_id: null, // job_id bg job
            job_loading: false, // bg job loading
            unfoldAll: false,
            loading: false,
            invoices: [], // main invoices
            missing: [], // missing data invoices
            selectedInvoices: [], // checkbox containing selections
            showResult: false, // final result after processing
            counts: {
                ready: 0,
                missing: 0
            },
            view: "active" // or inacvtive
        };
    },
    computed: {
        selectAll: {
            get: function() {
                return this.invoices
                    ? this.selectedInvoices.length == this.invoices.length
                    : false;
            },

            set: function(v) {
                let selected = [];

                if (v) {
                    this.invoices.forEach(function(v, index) {
                        selected.push(index);
                    });
                }

                this.selectedInvoices = selected;
            }
        }
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);
        await this.fetchFilters();
        this.apiPaginator.sort = "invoice_date";
        this.apiPaginator.direction = "asc";
        this.apiFilterView = this.dateRanges[1].value;
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch (once) active filters
         * It does also localize the label sent from the backend
         */
        async fetchFilters() {
            try {
                this.loading = true;
                const r = await InvoiceService.invoiceFilters();
                let range = [];

                r.data.data.forEach(v => {
                    range.push({
                        label: this.$t("invoices.dropdown_filter_until", {
                            date: this.$d(new Date(v.label), "short")
                        }),
                        value: v.value
                    });
                });

                this.dateRanges = range;
            } catch (e) {}
        },
        /**
         * Fetch invoices
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await InvoiceService.upcomingSubscriptionInvoices(
                    params
                );
                // These two must be reset
                this.invoices = [];
                this.selectedInvoices = [];
                this.missing = [];
                this.counts = {
                    ready: 0,
                    missing: 0
                };

                r.data.data.forEach(obj => {
                    if (obj.ready) {
                        this.counts.ready += 1;
                        this.invoices.push({ ...obj, visible: false });
                    } else {
                        this.counts.missing += 1;
                        this.missing.push(obj);
                    }
                });

                //this.$emit("updateCounts", this.counts);
            } catch (e) {
                console.log("Exception", e);
            }

            this.loading = false;
        },
        /**
         * Send invoices
         * What is sent to the backend is the invoice entry ;)
         */
        async sendInvoices() {
            try {
                this.job_loading = true;
                let copy = [];

                this.selectedInvoices.forEach(key => {
                    copy.push(this.invoices[key]);
                });

                const r = await InvoiceService.invoiceSubscriptions(copy);
                this.job_id = r.data.data.job_id;
            } catch (e) {
                this.job_loading = false;
                console.log("Exception", e);
            }
        },
        /**
         * Ready
         */
        async doneInvoices(result) {
            this.job_loading = false;
            this.job_id = null;
            console.log(result);
            this.result = result;
            this.showResult = true;
            //this.fetchData();
        },
        async closeInvoices() {
            this.showResult = false;
            await this.fetchData();
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag />
            <div class="row pt-5">
                <div class="col">
                    <v-stats-card
                        :title="$t('ready_to_invoice')"
                        type="default"
                        :value="counts.ready"
                        icon="fal fa-file-export"
                        class="mark-clickable"
                        @click.native="view = 'active'"
                        :dactive="view === 'active'"
                    />
                </div>

                <div class="col">
                    <v-stats-card
                        :title="$t('missing_invoice_data')"
                        type="default"
                        :value="counts.missing"
                        icon="fal fa-file-invoice"
                        :dactive="view === 'inactive'"
                        class="mark-clickable"
                        @click.native="view = 'inactive'"
                    />
                </div>
            </div>
        </base-header>
        <div class="container-fluid">
            <!-- INACTIVE INVOICES -->
            <template v-if="view === 'inactive'">
                <div class="row card-wrapper">
                    <div class="col-12">
                        <v-loader :loading="loading" v-if="loading" />

                        <card body-classes="px-0" v-else>
                            <template v-if="missing.length">
                                <div class="table-responsive">
                                    <v-table class="table table-mg table-hover">
                                        <v-thead>
                                            <v-tr>
                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="invoice_date"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.invoice_date_short"
                                                            )
                                                        }}</v-sorter
                                                    ></v-th
                                                >

                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="period_start"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.period"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>
                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="customer.name"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.customer"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>
                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="invoice_text"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.subscription_type"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>

                                                <v-th class="">
                                                    {{ $t("invoices.reason") }}
                                                </v-th>
                                                <v-th class="text-center">
                                                </v-th>
                                            </v-tr>
                                        </v-thead>
                                        <v-tbody>
                                            <template
                                                v-for="(invoice,
                                                index) in missing"
                                            >
                                                <v-tr :key="index">
                                                    <v-td>
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    invoice.invoice_date
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                        <pre v-if="false">{{
                                                            invoice
                                                        }}</pre>
                                                    </v-td>
                                                    <v-td>
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    invoice.period_start
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                        -
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    invoice.period_end
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                    </v-td>
                                                    <v-td>
                                                        <router-link
                                                            :to="{
                                                                name:
                                                                    'CustomersTabSubscriptions',
                                                                params: {
                                                                    customer_id:
                                                                        invoice
                                                                            .customer
                                                                            .id
                                                                }
                                                            }"
                                                            >{{
                                                                invoice.customer
                                                                    .name
                                                            }}</router-link
                                                        >
                                                    </v-td>
                                                    <v-td>
                                                        {{
                                                            invoice.subscription_type_name
                                                        }}
                                                    </v-td>
                                                    <v-td class="text-danger">
                                                        <template
                                                            v-if="
                                                                invoice.subscription_type ==
                                                                    2
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    "invoice.sub_missing_consumption_data"
                                                                )
                                                            }}
                                                        </template>
                                                        <template
                                                            v-else-if="
                                                                invoice.subscription_type ==
                                                                    3
                                                            "
                                                            >{{
                                                                $t(
                                                                    "invoice.sub_no_products_defined"
                                                                )
                                                            }}
                                                        </template>
                                                    </v-td>
                                                    <v-td class="text-right">
                                                        <router-link
                                                            :to="{
                                                                name:
                                                                    'CustomersTabSubscriptionDetails',
                                                                params: {
                                                                    customer_id:
                                                                        invoice.customer_id,
                                                                    subscription_id:
                                                                        invoice.subscription_id
                                                                }
                                                            }"
                                                            ><span
                                                                v-if="
                                                                    invoice.subscription_type ==
                                                                        2
                                                                "
                                                            >
                                                                {{
                                                                    $t(
                                                                        "invoice.enter_data"
                                                                    )
                                                                }}</span
                                                            ><span
                                                                v-if="
                                                                    invoice.subscription_type ==
                                                                        3
                                                                "
                                                                >{{
                                                                    $t(
                                                                        "invoice.define_products"
                                                                    )
                                                                }}</span
                                                            ><i
                                                                class="fa fa-arrow-right ml-1"
                                                            ></i>
                                                        </router-link>
                                                    </v-td>
                                                </v-tr>
                                            </template>
                                        </v-tbody>
                                    </v-table>
                                </div>
                            </template>
                            <div class="px-mg" v-else>
                                <div class="nice-info">
                                    {{ $t("invoice.no_invoices_to_send") }}
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </template>

            <!-- ACTIVE INVOICES -->
            <template v-else-if="view === 'active'">
                <div class="row card-wrapper">
                    <div class="col-12">
                        <v-filters
                            v-if="!loading"
                            :enable-filters="false"
                            :filter-views="dateRanges"
                            :filter-view="apiFilterView"
                            @filter-view-change="$handleFilterView($event)"
                            :filter-name="$t('invoices.period')"
                        >
                            <template
                                slot="left"
                                v-if="!loading && invoices.length"
                            >
                                <base-button
                                    type="secondary"
                                    @click="sendInvoices"
                                    v-if="ACL_RW"
                                    ><i class="far fa-file-export"></i>
                                    {{
                                        $t("invoices.send_selected")
                                    }}</base-button
                                >
                            </template>
                            <template slot="search">
                                <FormulateInput
                                    v-model="apiSearchFilter.customer"
                                    type="fsearch"
                                    :placeholder="$t('invoices.search')"
                                />
                            </template>
                        </v-filters>
                    </div>
                </div>

                <div class="row card-wrapper">
                    <div class="col-12">
                        <v-loader :loading="loading" v-if="loading" />

                        <card body-classes="px-0" v-else>
                            <template v-if="invoices.length">
                                <div class="table-responsive">
                                    <v-table class="table table-mg table-hover">
                                        <v-thead>
                                            <v-tr>
                                                <v-th
                                                    ><input
                                                        type="checkbox"
                                                        v-model="selectAll"
                                                /></v-th>
                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="invoice_date"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.invoice_date_short"
                                                            )
                                                        }}</v-sorter
                                                    ></v-th
                                                >

                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="period_start"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.period"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>
                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="customer.name"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.customer"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>
                                                <v-th>
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="invoice_text"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.description"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>

                                                <v-th class="text-right">
                                                    <v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="amount"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "invoices.amount"
                                                            )
                                                        }}</v-sorter
                                                    >
                                                </v-th>
                                                <v-th class="text-center">
                                                    <base-button
                                                        type="primary"
                                                        size="sm"
                                                        :outline="true"
                                                        @click="
                                                            unfoldAll = !unfoldAll
                                                        "
                                                        v-if="invoices.length"
                                                    >
                                                        <i
                                                            class="far"
                                                            :class="{
                                                                'fa-chevron-down': !unfoldAll,
                                                                'fa-chevron-right': unfoldAll
                                                            }"
                                                        ></i>
                                                    </base-button>
                                                </v-th>
                                            </v-tr>
                                        </v-thead>
                                        <v-tbody>
                                            <template
                                                v-for="(invoice,
                                                index) in invoices"
                                            >
                                                <v-tr :key="index">
                                                    <v-td
                                                        ><input
                                                            type="checkbox"
                                                            :value="index"
                                                            v-model="
                                                                selectedInvoices
                                                            "
                                                    /></v-td>
                                                    <v-td>
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    invoice.invoice_date
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                        <div
                                                            class="small text-muted"
                                                        >
                                                            {{
                                                                invoice.subscription_type_name
                                                            }}
                                                            (#{{
                                                                invoice.subscription_id
                                                            }})
                                                        </div>
                                                        <pre v-if="false">{{
                                                            invoice
                                                        }}</pre>
                                                    </v-td>
                                                    <v-td>
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    invoice.period_start
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                        -
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    invoice.period_end
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                    </v-td>
                                                    <v-td>
                                                        <router-link
                                                            :to="{
                                                                name:
                                                                    'CustomersTabSubscriptions',
                                                                params: {
                                                                    customer_id:
                                                                        invoice
                                                                            .customer
                                                                            .id
                                                                }
                                                            }"
                                                            >{{
                                                                invoice.customer
                                                                    .name
                                                            }}</router-link
                                                        >
                                                    </v-td>
                                                    <v-td>
                                                        <ul
                                                            class="pl-2"
                                                            v-if="
                                                                Array.isArray(
                                                                    invoice.invoice_text
                                                                )
                                                            "
                                                        >
                                                            <li
                                                                v-for="(il,
                                                                ilx) in invoice.invoice_text"
                                                                :key="ilx"
                                                            >
                                                                {{ il }}
                                                            </li>
                                                        </ul>
                                                        <template v-else>
                                                            {{
                                                                invoice.invoice_text
                                                            }}</template
                                                        >
                                                    </v-td>
                                                    <v-td class="text-right">
                                                        {{
                                                            $n(invoice.amount, {
                                                                style:
                                                                    "currency",
                                                                currency:
                                                                    invoice.currency,
                                                                currencyDisplay:
                                                                    "code"
                                                            })
                                                        }}
                                                    </v-td>
                                                    <v-td class="text-center">
                                                        <i
                                                            class="m-click far"
                                                            :class="{
                                                                'fa-chevron-down': !invoice.visible,
                                                                'fa-chevron-right':
                                                                    invoice.visible
                                                            }"
                                                            @click="
                                                                invoice.visible = !invoice.visible
                                                            "
                                                        ></i>
                                                    </v-td>
                                                </v-tr>
                                                <transition
                                                    name="fade"
                                                    :key="`transition_${index}`"
                                                >
                                                    <v-tr
                                                        :key="`detail_${index}`"
                                                        v-if="
                                                            invoice.visible ||
                                                                unfoldAll
                                                        "
                                                    >
                                                        <v-td colspan="9">
                                                            <div class="row">
                                                                <div
                                                                    class="col-6"
                                                                >
                                                                    <dl>
                                                                        <dt>
                                                                            {{
                                                                                $t(
                                                                                    "invoice.payment_strategy"
                                                                                )
                                                                            }}
                                                                        </dt>
                                                                        <dd>
                                                                            {{
                                                                                invoice.payment_strategy_name
                                                                            }}
                                                                        </dd>

                                                                        <dt>
                                                                            {{
                                                                                $t(
                                                                                    "invoice.payment_frequency"
                                                                                )
                                                                            }}
                                                                        </dt>
                                                                        <dd>
                                                                            {{
                                                                                $matchInHash(
                                                                                    mgPaymentFrequencyHash,
                                                                                    invoice.frequency
                                                                                )
                                                                            }}
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                                <div
                                                                    class="col-6"
                                                                >
                                                                    <dl>
                                                                        <template
                                                                            v-if="
                                                                                invoice.subscription_type !=
                                                                                    3
                                                                            "
                                                                        >
                                                                            <dt>
                                                                                {{
                                                                                    $t(
                                                                                        "invoice.economic_product"
                                                                                    )
                                                                                }}
                                                                            </dt>
                                                                            <dd>
                                                                                {{
                                                                                    invoice.economic_product_name
                                                                                }}
                                                                            </dd>
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                invoice.subscription_type ==
                                                                                    2
                                                                            "
                                                                        >
                                                                            <dt>
                                                                                {{
                                                                                    $t(
                                                                                        "invoice.consumption"
                                                                                    )
                                                                                }}
                                                                            </dt>
                                                                            <dd>
                                                                                {{
                                                                                    $n(
                                                                                        invoice
                                                                                            .consumption
                                                                                            .qty,
                                                                                        "usage"
                                                                                    )
                                                                                }}
                                                                                {{
                                                                                    invoice.unit_name
                                                                                }}
                                                                                x
                                                                                {{
                                                                                    $n(
                                                                                        invoice
                                                                                            .consumption
                                                                                            .item_price,
                                                                                        "usage"
                                                                                    )
                                                                                }}
                                                                                {{
                                                                                    invoice.currency
                                                                                }}
                                                                            </dd>
                                                                        </template>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </v-td>
                                                    </v-tr>
                                                </transition>
                                            </template>
                                        </v-tbody>
                                    </v-table>
                                </div>
                            </template>
                            <div class="px-mg" v-else>
                                <div class="nice-info">
                                    {{ $t("invoice.no_invoices_to_send") }}
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </template>
            <v-queue
                :name="$t('invoices.creating_invoices')"
                :job_id="job_id"
                :loading="job_loading"
                @task-ready="doneInvoices"
            />

            <portal to="modals" v-if="showResult">
                <modal
                    size="xl"
                    :show="true"
                    :showClose="true"
                    class="invoice-result-modal"
                    @close="closeInvoices"
                >
                    <template slot="header">
                        {{ $t("invoices.result") }}
                    </template>

                    <template slot="default">
                        <v-table class="table table-sm table-mg">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("invoices.customer") }}</v-th>
                                    <v-th>{{
                                        $t("invoices.invoice_date")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoices.amount")
                                    }}</v-th>
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr
                                    v-for="(r, index) in result.result"
                                    :key="index"
                                >
                                    <v-td>
                                        {{ r.invoice.debtor.name }}
                                        <div
                                            class="alert alert-danger alert-outline mt-3"
                                            v-if="r.ec_result.status == false"
                                        >
                                            {{
                                                $t("invoice.error_introduction")
                                            }}
                                            <div
                                                style="font-style: italic"
                                                class="mt-2"
                                            >
                                                {{ r.ec_result.error }}
                                            </div>
                                        </div>
                                    </v-td>
                                    <v-td>
                                        {{
                                            $d(
                                                new Date(r.entity.invoice_date),
                                                "short"
                                            )
                                        }}
                                    </v-td>
                                    <v-td class="text-right">
                                        {{
                                            $n(r.invoice.payload.amount, {
                                                style: "currency",
                                                currency:
                                                    r.invoice.payload.currency,
                                                currencyDisplay: "code"
                                            })
                                        }}
                                    </v-td>
                                    <v-td class="text-right">
                                        <i
                                            class="far"
                                            :class="{
                                                'fa-check': r.ec_result.status,
                                                'fa-times': !r.ec_result.status
                                            }"
                                        ></i>
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                    </template>
                </modal>
            </portal>
        </div>
    </div>
</template>
