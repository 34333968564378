<script>
import VChargeTemplateForm from "@/components/Forms/VChargeTemplateForm";
import ChargeTemplateService from "@/services/ChargeTemplateService";
import VFilters from "@/components/Search/VFilters";

export default {
    name: "VIndex",
    components: {
        VChargeTemplateForm,
        VFilters,
    },
    data() {
        return {
            loading: false,
            charge_tpls: [],
            editing: false,
            editContext: null,
            showNote: false,
            showNoteContext: null,
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiSearchFilter = { name: "" };
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch all
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await ChargeTemplateService.GET(params);
                this.charge_tpls = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         * Finish
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
        /**
         * Show note
         */
        doShowNote(ctx) {
            this.showNote = true;
            this.showNoteContext = ctx;
        },
        /**
         * HIde note
         */
        doHideNote() {
            this.showNoteContext = null;
            this.showNote = false;
        },
    },
};
</script>

<template>
    <!-- one-time services :: lines -->
    <div class="v-charges">
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    v-if="ACL_RW"
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("charge_tpls.add_new") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        :search="true"
                        v-model="apiSearchFilter.name"
                        :search-placeholder="$t('charge_tpls.search')"
                    >
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <!-- table -->
                        <v-table
                            class="table table-sm table-mg"
                            v-else-if="charge_tpls.length"
                        >
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("charge_tpls.name") }}</v-th>
                                    <v-th>{{
                                        $t("charge_tpls.invoice_line")
                                    }}</v-th>
                                    <v-th>{{
                                        $t("charge_tpls.economic_product")
                                    }}</v-th>
                                    <v-th class="text-center">{{
                                        $t("charge_tpls.notes")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("charge_tpls.amount")
                                    }}</v-th>
                                    <v-th class="">{{
                                        $t("charge_tpls.currency")
                                    }}</v-th>

                                    <v-th v-if="ACL_RW"></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr v-for="c in charge_tpls" :key="c.id">
                                    <v-td>{{ c.name }}</v-td>
                                    <v-td
                                        ><v-empty>{{
                                            c.invoice_text
                                        }}</v-empty></v-td
                                    >
                                    <v-td
                                        ><v-empty>{{
                                            c.economic_product_name
                                        }}</v-empty></v-td
                                    >

                                    <v-td class="text-center">
                                        <i
                                            class="far fa-comment-alt-lines fa-lg m-click"
                                            @click="doShowNote(c.description)"
                                            v-if="c.description"
                                        ></i>
                                        <i
                                            class="far fa-comment-alt-slash text-muted fa-lg"
                                            v-else
                                        ></i>
                                    </v-td>

                                    <v-td class="text-right">
                                        <template v-if="c.amount > 0">{{
                                            $n(c.amount, "money")
                                        }}</template>
                                        <v-empty v-else />
                                    </v-td>
                                    <v-td
                                        ><v-empty>{{
                                            c.currency
                                        }}</v-empty></v-td
                                    >

                                    <v-td class="text-right" v-if="ACL_RW">
                                        <i
                                            class="far fa-pencil m-click fa-lg"
                                            @click="setContext(c)"
                                        ></i>
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>

                        <div
                            class="alert alert-warning alert-outline ml-4 mr-4"
                            v-else
                        >
                            {{ $t("charge_tpls.no_charge_templates_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>

        <portal to="modals">
            <modal v-if="showNote" size="lg" :show="true" @close="doHideNote">
                <template slot="header">
                    {{ $t("charge_tpls.note_header") }}
                </template>
                <template slot="default">
                    {{ showNoteContext }}
                </template>
            </modal>
            <v-charge-template-form
                v-if="editing"
                :context="editContext"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>



<style>
</style>