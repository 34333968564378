<script>
import MGConsts from "@/mixins/MGConsts";
import nl2br from "@/filters/nl2br";
import EcService from "@/services/EcService";
import InvoiceService from "@/services/InvoiceService";
import Messages from "@/mixins/Messages";
import AppCfg from "@/mixins/AppCfg";

export default {
    components: {},
    name: "VShowInvoice",
    props: {
        invoice: {
            description: "Invoice object or null",
        },
        project: {
            type: Boolean,
            default: false,
            description:
                "true if presenting a project invoice with buttons navi etc.",
        },
    },
    mixins: [MGConsts, Messages, AppCfg],
    data() {
        return {
            termsOfPayment: [],
            layouts: [],
            invoice_layout: "",
            invoice_terms: "",
            loading: false,
            dealLoading: false,
            deal: "meg", // TMP
        };
    },
    computed: {
        invoiceSetups() {
            return this.appCfgRead('Projects.invoice_setup');
        }
    },
    async created() {

    },
    methods: {
        handler() {
            this.updateInvoice();
        },
        async updateInvoice() {
            try {
                this.loading = true;
                let data = {};
                data["ec_req_layout"] = this.invoice_layout;
                data["ec_req_terms"] = this.invoice_terms;
                const r = await InvoiceService.PUT_updateProjectLayoutTerms(
                    this.invoice.id,
                    data
                );
                this.$msgSuccess(this.$t("invoice.data_updated"));
                this.$emit("update", {
                    invoice_id: this.invoice.id,
                    ec_req_layout: r.data.data.ec_req_layout,
                    ec_req_terms: r.data.data.ec_req_terms,
                });
            } catch (e) {
                console.log("update exception", e);
            }

            this.loading = false;
        },
        nl(text) {
            return nl2br(text);
        },
        /**
         * Navigate to editing
         */
        editInvoice() {
            this.$router.push({
                name: "ProjectViewInvoice",
                params: {
                    project_id: this.invoice.project_id,
                    invoice_id: this.invoice.id,
                },
            });
        },
        /**
         * Send the invoice further
         * TMP: change of emit paras, includes now deal as well (and is an object, no longer an int)
         */
        send() {
            // NOTICE: different format: was just the id as sole argument
            this.$emit("send", {
                invoice_id: this.invoice.id,
                deal: this.deal,
            });
        },

    },
};
</script>

<template>
    <modal
        size="xl"
        :show="invoice !== null"
        :showClose="true"
        class="invoice-show-modal"
        @close="$emit('close')"
    >
        <template slot="header">
            <template v-if="!project">
                {{ $t("invoice.invoice_data") }} -
                {{ $t("invoice.sent_to_economic_on") }}
                {{ $d(new Date(invoice.ec_transfer_date), "long") }}
            </template>
            <template v-else>
                {{ $t("invoice.ready_to_invoice_project") }}
                <base-button
                    size="sm"
                    type="primary"
                    class="ml-3"
                    @click="send"
                    outline
                    ><i class="far fa-file-export"></i>
                    {{ $t("invoice.send") }}</base-button
                >
            </template>
        </template>

        <template slot="default">
            <v-loader v-if="dealLoading" />
            <template v-else>
                <div class="row">
                    <div class="col-12" v-if="invoice.bkeeper_message">
                        <div class="alert alert-info alert-outline">
                            <dl>
                                <dt>
                                    {{ $t("invoice.message_to_bookkeeper") }}
                                </dt>
                                <dd>
                                    <template v-if="invoice.bkeeper_message">
                                        <v-newlines
                                            :text="invoice.bkeeper_message"
                                        />
                                    </template>
                                    <template v-else>---</template>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div class="col">
                        <dl>
                            <template v-if="project">

                                <dt>{{$t("departments.invoice_setup")}}</dt>
                                <dd class="text-info text-bold">{{invoiceSetups[invoice.project.invoice_setup]}}</dd>



                                <dt>{{ $t("invoice.created_date") }}</dt>
                                <dd>
                                    {{ $d(new Date(invoice.created), "short") }}
                                </dd>
                                <dt>
                                    {{ $t("invoice.recommended_invoice_date") }}
                                </dt>
                                <dd>
                                    <template v-if="invoice.invoice_date">
                                        {{
                                            $d(
                                                new Date(invoice.invoice_date),
                                                "short"
                                            )
                                        }}
                                    </template>
                                    <template v-else>---</template>
                                </dd>
                            </template>
                            <template v-else>
                                <dt>{{ $t("invoice.invoice_date") }}</dt>
                                <dd>
                                    {{
                                        $d(
                                            new Date(invoice.invoice_date),
                                            "short"
                                        )
                                    }}
                                </dd>
                            </template>

                            <dt>{{ $t("invoice.customer") }}</dt>
                            <dd>
                                {{ invoice.customer.name }}
                            </dd>

                            <dt>{{ $t("invoice.invoice_type") }}</dt>
                            <dd>
                                {{
                                    $matchInHash(
                                        mgInvoiceTypesHash,
                                        invoice.invoice_type
                                    )
                                }}
                                <template v-if="invoice.project">
                                    ({{ invoice.project.project_model_name }})
                                </template>
                            </dd>

                            <template
                                v-if="invoice.seq_num && invoice.seq_total"
                            >
                                <dt>{{ $t("invoice.sequence_numbers") }}</dt>
                                <dd>
                                    {{
                                        $t("invoices.seq_num", {
                                            n: invoice.seq_num,
                                            of: invoice.seq_total,
                                        })
                                    }}
                                </dd>
                            </template>

                            <template
                                v-if="
                                    project || invoice.invoice_type == 'project'
                                "
                            >
                                <dt>{{ $t("invoice.case_responsible") }}</dt>
                                <dd>{{ invoice.project.user_name }}</dd>
                            </template>
                        </dl>
                    </div>
                    <div class="col">
                        <dl>
                            <dt>{{ $t("invoice.invoice_header") }}</dt>
                            <dd
                                v-if="invoice.invoice_header"
                                v-html="nl(invoice.invoice_header)"
                            ></dd>
                            <dd v-else>---</dd>

                            <dt>{{ $t("invoice.invoice_text1") }}</dt>
                            <dd
                                v-if="invoice.invoice_text1"
                                v-html="nl(invoice.invoice_text1)"
                            ></dd>
                            <dd v-else>---</dd>

                            <dt>{{ $t("invoice.invoice_text2") }}</dt>
                            <dd
                                v-if="invoice.invoice_text2"
                                v-html="nl(invoice.invoice_text2)"
                            ></dd>
                            <dd v-else>---</dd>
                        </dl>
                        <base-button
                            v-if="project"
                            @click="editInvoice"
                            size="sm"
                            type="primary"
                            ><i class="far fa-pencil"></i>
                            {{ $t("invoice.go_to_editing") }}</base-button
                        >
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-12">
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("invoice.line_number") }}</v-th>
                                    <v-th>{{ $t("invoice.description") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice.quantity")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice.price")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice.discount")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice.amount")
                                    }}</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr
                                    v-for="line in invoice.invoice_lines"
                                    :key="line.id"
                                >
                                    <v-td>
                                        {{ line.line_number }}
                                    </v-td>
                                    <v-td
                                        ><v-newlines :text="line.invoice_line"
                                    /></v-td>
                                    <v-td class="text-right">{{
                                        line.qty
                                    }}</v-td>
                                    <v-td class="text-right">{{
                                        $n(line.price, {
                                            style: "currency",
                                            currency: invoice.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td>
                                    <v-td class="text-right"
                                        ><template
                                            v-if="line.discount_perc > 0"
                                        >
                                            {{ $n(line.discount_perc) }}%
                                        </template>
                                        <template v-else>---</template></v-td
                                    >
                                    <v-td class="text-right">{{
                                        $n(line.price_subtotal, {
                                            style: "currency",
                                            currency: invoice.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td>
                                </v-tr>
                            </v-tbody>
                            <tfoot>
                                <v-tr>
                                    <v-td colspan="5" class="text-right">{{
                                        $t("invoice.net_total")
                                    }}</v-td>
                                    <v-td class="text-right">{{
                                        $n(invoice.total_net_amount, {
                                            style: "currency",
                                            currency: invoice.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td>
                                </v-tr>
                            </tfoot>
                        </v-table>
                    </div>
                </div>
            </template>
        </template>
    </modal>
</template>

<style lang="scss">
.invoice-show-modal {
    table {
        tfoot {
            tr {
                background: $gray-400;
            }

            td {
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
}
</style>
