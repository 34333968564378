<script>
/** 
    Hard missing data - last BOX (i.e. cannot even create a basic skeleton for an invoice)
 */
import InvoiceService from "@/services/InvoiceService";

export default {
    name: "VMissingData",
    data() {
        return {
            dateRanges: [],
            loading: false,
            wrong: [],
            counts: {
                ready: 0,
                missing: 0,
                error: 0,
            },
        };
    },
    async created() {
        await this.fetchFilters();
        this.apiPaginator.sort = "invoice_date";
        this.apiPaginator.direction = "asc";
        this.apiFilterView = this.dateRanges[1].value;
        this.$apiEnableWatcher();
        await this.fetchData(); 
    },
    methods: {
        /**
         * Fetch (once) active filters
         * It does also localize the label sent from the backend
         */
        async fetchFilters() {
            try {
                this.loading = true;
                const r = await InvoiceService.invoiceFilters();
                let range = [];

                r.data.data.forEach((v) => {
                    range.push({
                        label: this.$t("invoices.dropdown_filter_until", {
                            date: this.$d(new Date(v.label), "short"),
                        }),
                        value: v.value,
                    });
                });

                this.dateRanges = range;
            } catch (e) {}
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params['cview'] = 'error'; 
                const r = await InvoiceService.upcomingInvoices(params);
                this.wrong = r.data.data.error;
                this.counts.ready = r.data.data.ready_count;
                this.counts.missing = r.data.data.missing_count;
                this.counts.error = r.data.data.error_count;
                this.$emit("updateCounts", this.counts);
                console.log(this.wrong);
            } catch (e) {}

            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left"></div>
                    <div class="mg-title__right"></div>
                </div>
            </div>
        </div>
        <!-- headings -->

        <div class="row card-wrapper">
            <div class="col-12">
                <card body-classes="px-0">
                    <v-loader :loading="loading" v-if="loading" />

                    <template v-if="wrong.length">
                        <div class="table-responsive">
                            <v-table class="table table-mg table-hover">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("invoice.customer")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("invoice.resp_invoicing")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("invoice.resp_production")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("invoice.missing_data")
                                        }}</v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(w, index) in wrong"
                                        :key="index"
                                    >
                                        <v-td>
                                            <router-link
                                                :to="{
                                                    name:
                                                        'CustomersTabContracts',
                                                    params: {
                                                        customer_id:
                                                            w.customer.id,
                                                    },
                                                }"
                                            >
                                                {{
                                                    w.customer.name
                                                }}</router-link
                                            >
                                        </v-td>
                                        <v-td>
                                            <template v-if="w.finance_user">
                                                {{ w.finance_user.name }}
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td
                                            ><template v-if="w.operations_user">
                                                {{ w.operations_user.name }}
                                            </template>
                                            <template v-else
                                                >---</template
                                            ></v-td
                                        >
                                        <v-td>
                                            <ul class="list-unstyled">
                                                <li
                                                    v-for="(
                                                        msg, idx
                                                    ) in w.errors"
                                                    :key="idx"
                                                    class="text-danger"
                                                >
                                                    {{ msg }}
                                                </li>
                                            </ul>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                    </template>

                    <div class="pb-mg" v-else>
                        <div class="nice-info">
                            {{ $t("invoice.no_invoices_with_missing_data") }}
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>