<script>
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VContractWidget",
    mixins: [MGConsts],
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    methods: {
        acc(p, k) {
            let tmp = p;
            return p[k];
        },
    },
};
</script>

<template>
    <div class="v-contract-widget">
        
        <v-table class="table table-sm">
            <v-tr>
                <v-td>{{ $t("contract.type") }}</v-td>
                <v-td>
                    {{ acc(mgContractType, contract.contract_type) }}
                </v-td>
            </v-tr>

            <v-tr>
                <v-td>{{ $t("contract.non_notice_period") }}</v-td>
                <v-td>
                    {{ $d(new Date(contract.from_date), 'short') }} - {{ $d(new Date(contract.to_date), 'short') }}
                </v-td>
            </v-tr>

            <v-tr v-if="contract.contract_type == 1">
                <v-td>{{$t('contract.base_fee')}}</v-td>
                <v-td>{{$n(contract.base_fee, {style: 'currency', currency: contract.currency, currencyDisplay: 'code'})}}</v-td>
            </v-tr>
            <v-tr v-if="contract.contract_type == 2">
                <v-td>{{$t('contract.base_fee_perc')}}</v-td>
                <v-td>
                    {{$n(contract.base_fee_perc, 'decimal')}}%
                </v-td>
            </v-tr>

            <v-tr>
                <v-td>
                    {{ $t("contract.frequency") }}:
                    {{ contract.frequency_object.number }}
                    {{ contract.frequency_object.word }} / {{ acc(mgPaymentStrategy, contract.payment_strategy) }}
                </v-td>
                <v-td>
                    {{ acc(mgAdjustmentType, contract.adjustment_type) }}
                    <span v-if="contract.adjustment_type == 2">({{$n(contract.adjustment_perc, 'decimal')}}%)</span>                    
                    <span v-if="contract.adjustment_type == 3 && parseFloat(contract.min_adjustment_perc) > 0.0">
                        ({{$t('contract.min_regulation')}}: {{$n(contract.min_adjustment_perc, 'decimal')}}%)
                    </span>
                </v-td>
            </v-tr>         

            <v-tr v-if="contract.shift_frequency">
                <v-td class="text-danger">{{$t('contract.shift_payment_notice')}}</v-td>
                <v-td>
                    {{ contract.shift_frequency_object.number }}
                    {{ contract.shift_frequency_object.word }}
                </v-td>
            </v-tr>   

            
        </v-table>
        
    </div>
</template>

<style>
</style>