<script>
import InvoiceService from "@/services/InvoiceService";
import VFilters from "@/components/Search/VFilters";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VInvoicing",
    mixins: [MGConsts],
    components: { VFilters },
    data() {
        return {
            dateRanges: [],
            job_id: null, // job_id bg job
            job_loading: false, // bg job loading
            unfoldAll: false,
            loading: false,
            invoices: [], // main invoices
            selectedInvoices: [], // checkbox containing selections
            showResult: false, // final result after processing
        };
    },
    computed: {
        selectAll: {
            get: function () {
                return this.invoices
                    ? this.selectedInvoices.length == this.invoices.length
                    : false;
            },

            set: function (v) {
                let selected = [];

                if (v) {
                    this.invoices.forEach(function (v, index) {
                        selected.push(index);
                    });
                }

                this.selectedInvoices = selected;
            },
        },
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);
        await this.fetchFilters();
        this.apiPaginator.sort = "invoice_date";
        this.apiPaginator.direction = "asc";
        this.apiFilterView = this.dateRanges[1].value;
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch (once) active filters
         * It does also localize the label sent from the backend
         */
        async fetchFilters() {
            try {
                this.loading = true;
                const r = await InvoiceService.invoiceFilters();
                let range = [];

                r.data.data.forEach((v) => {
                    range.push({
                        label: this.$t("invoices.dropdown_filter_until", {
                            date: this.$d(new Date(v.label), "short"),
                        }),
                        value: v.value,
                    });
                });

                this.dateRanges = range;
            } catch (e) {}
        },
        /**
         * Fetch invoices
         */
        async fetchData() {
            try {
                this.loading = true;
                const r = await InvoiceService.upcomingChargeInvoices();
                // These two must be reset
                this.invoices = [];
                this.selectedInvoices = [];
                r.data.data.forEach((obj) => {
                    this.invoices.push({ ...obj, visible: false });
                });

                //this.$emit("updateCounts", this.counts);
            } catch (e) {
                console.log("Exception", e);
            }

            this.loading = false;
        },
        /**
         * Send invoices
         */
        async sendInvoices() {
            try {
                this.job_loading = true;
                let copy = [];

                this.selectedInvoices.forEach((key) => {
                    copy.push(this.invoices[key]);
                });

                const r = await InvoiceService.invoiceCharges(copy);
                this.job_id = r.data.data.job_id;
            } catch (e) {
                this.job_loading = false;
                console.log("Exception", e);
            }
        },
        /**
         * Ready
         */
        async doneInvoices(result) {
            this.job_loading = false;
            this.job_id = null;
            console.log(result);
            this.result = result;
            this.showResult = true;
            //this.fetchData();
        },
        async closeInvoices() {
            this.showResult = false;
            await this.fetchData();
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag />
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        v-if="!loading"
                        :enable-filters="false"
                        :filter-views="dateRanges"
                        :filter-view="apiFilterView"
                        @filter-view-change="$handleFilterView($event)"
                        :filter-name="$t('invoices.period')"
                        v-model="apiSearchFilter.customer"
                        :search="true"
                        :search-placeholder="$t('invoices.search')"
                    >
                        <template
                            slot="left"
                            v-if="!loading && invoices.length"
                        >
                            <base-button
                                type="secondary"
                                @click="sendInvoices"
                                v-if="ACL_RW"
                                ><i class="far fa-file-export"></i>
                                {{ $t("invoices.send_selected") }}</base-button
                            >
                        </template>
                    </v-filters>
                </div>
            </div>

            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />

                    <card body-classes="px-0" v-else>
                        <template v-if="invoices.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><input
                                                    type="checkbox"
                                                    v-model="selectAll"
                                            /></v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoice_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_date_short"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="customer.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.customer")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoice_text"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.description"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="amount"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.amount")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-center">
                                                <base-button
                                                    type="primary"
                                                    size="sm"
                                                    :outline="true"
                                                    @click="
                                                        unfoldAll = !unfoldAll
                                                    "
                                                    v-if="invoices.length"
                                                >
                                                    <i
                                                        class="far"
                                                        :class="{
                                                            'fa-chevron-down': !unfoldAll,
                                                            'fa-chevron-right': unfoldAll,
                                                        }"
                                                    ></i>
                                                </base-button>
                                            </v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <template
                                            v-for="(invoice, index) in invoices"
                                        >
                                            <v-tr :key="index">
                                                <v-td
                                                    ><input
                                                        type="checkbox"
                                                        :value="index"
                                                        v-model="
                                                            selectedInvoices
                                                        "
                                                /></v-td>
                                                <v-td>
                                                    {{
                                                        $d(
                                                            new Date(
                                                                invoice.invoice_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                    <pre v-if="false">{{
                                                        invoice
                                                    }}</pre>
                                                </v-td>

                                                <v-td>
                                                    <router-link
                                                        :to="{
                                                            name:
                                                                'CustomersTabContracts',
                                                            params: {
                                                                customer_id:
                                                                    invoice
                                                                        .customer
                                                                        .id,
                                                            },
                                                        }"
                                                        >{{
                                                            invoice.customer
                                                                .name
                                                        }}</router-link
                                                    >
                                                </v-td>
                                                <v-td>
                                                    <template
                                                        v-if="
                                                            invoice.invoice_text
                                                        "
                                                        >{{
                                                            invoice.invoice_text
                                                        }}</template
                                                    >
                                                    <template v-else
                                                        >{{
                                                            invoice.economic_product_name
                                                        }}
                                                        <div
                                                            class="small text-muted"
                                                        >
                                                            {{
                                                                $t(
                                                                    "invoice.use_economic_product"
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                </v-td>
                                                <v-td class="text-right">
                                                    {{
                                                        $n(invoice.amount, {
                                                            style: "currency",
                                                            currency:
                                                                invoice.currency,
                                                            currencyDisplay:
                                                                "code",
                                                        })
                                                    }}
                                                </v-td>
                                                <v-td class="text-center">
                                                    <i
                                                        class="m-click far"
                                                        :class="{
                                                            'fa-chevron-down': !invoice.visible,
                                                            'fa-chevron-right':
                                                                invoice.visible,
                                                        }"
                                                        @click="
                                                            invoice.visible = !invoice.visible
                                                        "
                                                    ></i>
                                                </v-td>
                                            </v-tr>
                                            <transition
                                                name="fade"
                                                :key="`transition_${index}`"
                                            >
                                                <v-tr
                                                    :key="`detail_${index}`"
                                                    v-if="
                                                        invoice.visible ||
                                                        unfoldAll
                                                    "
                                                >
                                                    <v-td colspan="9">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <dl>
                                                                    <dt>
                                                                        {{
                                                                            $t(
                                                                                "invoice.economic_product"
                                                                            )
                                                                        }}
                                                                    </dt>
                                                                    <dd>
                                                                        {{
                                                                            invoice.economic_product_name
                                                                        }}
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </v-td>
                                                </v-tr>
                                            </transition>
                                        </template>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>
                        <div class="px-mg" v-else>
                            <div class="nice-info">
                                {{ $t("invoice.no_invoices_to_send") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <v-queue
                :name="$t('invoices.creating_invoices')"
                :job_id="job_id"
                :loading="job_loading"
                @task-ready="doneInvoices"
            />

            <portal to="modals" v-if="showResult">
                <modal
                    size="xl"
                    :show="true"
                    :showClose="true"
                    class="invoice-result-modal"
                    @close="closeInvoices"
                >
                    <template slot="header">
                        {{ $t("invoices.result") }}
                    </template>

                    <template slot="default">
                        <v-table class="table table-sm table-mg">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("invoices.customer") }}</v-th>
                                    <v-th>{{
                                        $t("invoices.invoice_date")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoices.amount")
                                    }}</v-th>
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr
                                    v-for="(r, index) in result.result"
                                    :key="index"
                                >
                                    <v-td>
                                        {{ r.invoice.debtor.name }}
                                        <div
                                            class="alert alert-danger alert-outline mt-3"
                                            v-if="r.ec_result.status == false"
                                        >
                                            {{
                                                $t("invoice.error_introduction")
                                            }}
                                            <div
                                                style="font-style: italic"
                                                class="mt-2"
                                            >
                                                {{ r.ec_result.error }}
                                            </div>
                                        </div>
                                    </v-td>
                                    <v-td>
                                        {{
                                            $d(
                                                new Date(r.entity.invoice_date),
                                                "short"
                                            )
                                        }}
                                    </v-td>
                                    <v-td class="text-right">
                                        {{
                                            $n(r.invoice.payload.amount, {
                                                style: "currency",
                                                currency:
                                                    r.invoice.payload.currency,
                                                currencyDisplay: "code",
                                            })
                                        }}
                                    </v-td>
                                    <v-td class="text-right">
                                        <i
                                            class="far"
                                            :class="{
                                                'fa-check': r.ec_result.status,
                                                'fa-times': !r.ec_result.status,
                                            }"
                                        ></i>
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                    </template>
                </modal>
            </portal>
        </div>
    </div>
</template>


<style>
</style>