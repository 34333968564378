import Http from "@/libraries/Http";

export default {
    apiVersion: 'v1',
    RESTroute: 'invoices',


    async GET_tmpGetEcDeals(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/tmp-get-ec-deals`, {params: params});
    },

    /**
     * All endpoints are implemented under invoices.
     * Obtain economic values.
     *
     */

    /**
     * Boilerplate
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {params: params});
    },

    async ecLayoutsAndTerms(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/ec-layouts-and-terms`, {params: params});
    },

    async ecTermsOfPayment(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/ec-terms-of-payment`, {params: params});
    },

    async ecLayouts(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/ec-layouts`, {params: params});
    },

    /**
     * Get products
     */
    async ecProducts(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/ec-products`, {params: params});
    },


}
