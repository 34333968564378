var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):_vm._e(),(_vm.wrong.length)?[_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-mg table-hover"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("invoice.customer")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("invoice.resp_invoicing")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("invoice.resp_production")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("invoice.missing_data")))])],1)],1),_c('v-tbody',_vm._l((_vm.wrong),function(w,index){return _c('v-tr',{key:index},[_c('v-td',[_c('router-link',{attrs:{"to":{
                                                name:
                                                    'CustomersTabContracts',
                                                params: {
                                                    customer_id:
                                                        w.customer.id,
                                                },
                                            }}},[_vm._v(" "+_vm._s(w.customer.name))])],1),_c('v-td',[(w.finance_user)?[_vm._v(" "+_vm._s(w.finance_user.name)+" ")]:[_vm._v("---")]],2),_c('v-td',[(w.operations_user)?[_vm._v(" "+_vm._s(w.operations_user.name)+" ")]:[_vm._v("---")]],2),_c('v-td',[_c('ul',{staticClass:"list-unstyled"},_vm._l((w.errors),function(msg,idx){return _c('li',{key:idx,staticClass:"text-danger"},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1)}),1)],1)],1)]:_c('div',{staticClass:"pb-mg"},[_c('div',{staticClass:"nice-info"},[_vm._v(" "+_vm._s(_vm.$t("invoice.no_invoices_with_missing_data"))+" ")])])],2)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mg-title mb-3"},[_c('div',{staticClass:"mg-title__left"}),_c('div',{staticClass:"mg-title__right"})])])])}]

export { render, staticRenderFns }