<script>
/**
    Missing invoice data - MIDDLE box
 */
import ContractDetailService from "@/services/ContractDetailService";
import InvoiceService from "@/services/InvoiceService";
import VFilters from "@/components/Search/VFilters";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VMissingInvoiceData",
    mixins: [MGConsts],
    components: { VFilters },
    data() {
        return {
            dateRanges: [],
            loading: false,
            missing: [],
            meta: {},
            counts: {
                ready: 0,
                missing: 0,
                error: 0
            }
        };
    },
    async created() {
        await this.fetchFilters();
        this.apiPaginator.sort = "invoicing_date";
        this.apiPaginator.direction = "asc";
        this.apiFilterView = this.dateRanges[1].value;
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch (once) active filters
         * It does also localize the label sent from the backend
         */
        async fetchFilters() {
            try {
                this.loading = true;
                const r = await InvoiceService.invoiceFilters();
                let range = [];

                r.data.data.forEach(v => {
                    range.push({
                        label: this.$t("invoices.dropdown_filter_until", {
                            date: this.$d(new Date(v.label), "short")
                        }),
                        value: v.value
                    });
                });

                this.dateRanges = range;
            } catch (e) {}
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["cview"] = "missing";
                const {
                    data
                } = await ContractDetailService.GET_contractInvoicing(params);

                this.missing = data.data;
                this.counts.ready = data.meta.ready_count;
                this.counts.missing = data.meta.missing_count;
                this.counts.error = data.meta.error_count;
                this.$emit("updateCounts", this.counts);
            } catch (e) {
                console.log("mis fe e", e);
            }

            this.loading = false;
        },
        downloadCSV() {
            const baseURL =
                window.location.protocol +
                window.location.host +
                "/v1/contract-details/contract-invoicing";

            // Define the query object with GET variables
            let params = this.$buildParams();
            params["cview"] = "missing";
            params["csv"] = "1";

            // Create a URL object and append the URL-encoded query string
            const url = new URL(baseURL);
            url.search = new URLSearchParams(params).toString();

            // Create a hidden link element
            const link = document.createElement("a");
            link.style.display = "none";
            document.body.appendChild(link);

            // Set the link's href and download attributes
            link.setAttribute("href", url.toString());
            link.setAttribute("download", "invoices-missing-data.csv");

            // Trigger a click event on the link to start the download
            link.click();

            // Remove the link element from the DOM
            document.body.removeChild(link);
        }
    }
};
</script>

<template>
    <div>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        v-if="!loading"
                        :enable-filters="false"
                        :filter-views="dateRanges"
                        :filter-view="apiFilterView"
                        @filter-view-change="$handleFilterView($event)"
                        :filter-name="$t('invoices.period')"
                        v-model="apiSearchFilter.customer"
                        :search="true"
                        :search-placeholder="$t('invoices.search')"
                    >
                        <template v-slot:right>
                            <base-button
                                size="sm"
                                type="secondary"
                                @click="downloadCSV"
                                ><i class="fa fa-file-csv"></i>
                                {{ $t("invoices.export_to_csv") }}</base-button
                            >
                        </template>
                    </v-filters>
                </div>
            </div>
            <!-- headings -->

            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />

                    <card body-classes="px-0" v-else>
                        <template v-if="missing.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoicing_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_date_short"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="contract_id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_id"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="contract_year"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_contract_year"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="entry_type"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.type")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th></v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Customers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.customer")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th>{{
                                                $t("invoice.missing_data")
                                            }}</v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="m in missing" :key="m.id">
                                            <v-td>{{
                                                $d(
                                                    new Date(m.invoicing_date),
                                                    "short"
                                                )
                                            }}</v-td>
                                            <v-td class="text-center"
                                                >#{{ m.contract_id }}</v-td
                                            >
                                            <v-td>
                                                {{ m.contract_year }}
                                            </v-td>
                                            <v-td
                                                >{{ m.entry_type_name }}
                                            </v-td>
                                            <v-td class="text-center">
                                                <i
                                                    v-if="m.contract.asset_type"
                                                    :class="
                                                        `far ${m.contract.asset_type.icon} fa-fw`
                                                    "
                                                ></i>
                                                <i
                                                    v-if="
                                                        m.contract
                                                            .contract_type == 2
                                                    "
                                                    :class="
                                                        `far fa-percent fa-fw`
                                                    "
                                                ></i>
                                            </v-td>
                                            <v-td>
                                                <router-link
                                                    :to="{
                                                        name:
                                                            'CustomersTabManageContract',
                                                        params: {
                                                            customer_id:
                                                                m.contract
                                                                    .customer
                                                                    .id,
                                                            contract_id:
                                                                m.contract.id
                                                        }
                                                    }"
                                                    >{{
                                                        m.contract.customer.name
                                                    }}</router-link
                                                >
                                                <div
                                                    v-if="
                                                        m.contract
                                                            .invoice_prefix_text
                                                    "
                                                >
                                                    {{
                                                        m.contract
                                                            .invoice_prefix_text
                                                    }}
                                                </div></v-td
                                            >
                                            <v-td class="text-danger">
                                                <template
                                                    v-if="m.entry_type == 2"
                                                >
                                                    {{
                                                        $t(
                                                            "invoice.missing_budget_data"
                                                        )
                                                    }}
                                                </template>
                                                <template
                                                    v-if="m.entry_type == 1"
                                                >
                                                    {{
                                                        $t(
                                                            "invoice.missing_data_preventing_period_calculation"
                                                        )
                                                    }}
                                                </template>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>

                        <div class="pb-mg" v-else>
                            <div class="nice-info">
                                {{
                                    $t("invoice.no_invoices_with_missing_data")
                                }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
