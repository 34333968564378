<script>
export default {
    name: "VInvoicing",
    data() {
        return {
            counts: {
                ready: 0,
                missing: 0,
                error: 0
            }
        };
    },
    methods: {
        setCounts(counts) {
            this.counts = counts;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>

            <div class="row pt-5">

                <div class="col">
                    <v-stats-card
                        :title="$t('ready_to_invoice')"
                        type="default"
                        :value="counts.ready"
                        icon="fal fa-file-export"
                        :navigate="{ name: 'ContractsInvoicingReady' }"
                    />
                </div>

                <div class="col">
                    <v-stats-card
                        :title="$t('missing_invoice_data')"
                        type="default"
                        :value="counts.missing"
                        icon="fal fa-file-invoice"
                        :navigate="{
                            name: 'ContractsInvoicingMissingInvoiceData'
                        }"
                    />
                </div>

                <div class="col" v-if="false" >
                    <v-stats-card
                        :title="$t('overview')"
                        type="default"
                        :value="counts.error"
                        icon="fal fa-search"
                        :navigate="{ name: 'ContractsInvoicingOverview' }"
                    />
                </div>
            </div>
        </base-header>
        <!-- End of header stats & navigation -->

        <router-view name="invoice" @updateCounts="setCounts"></router-view>
    </div>
</template>
