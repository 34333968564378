<script>
export default {
    name: "SVars",
    props: {
        vars: {
            description: "Object with variables",
        },
    },
};
</script>

<template>
    <div class="s-vars">
        <h4>{{ $t("settings.available_variables") }}</h4>
        <template v-if="Object.keys(vars).length">
            <ul class="list-unstyled">
                <li v-for="(value, key) in vars" :key="key">
                    <b>{{ "\{\{" }}{{ key }}{{ "\}\}" }} </b>
                    <i class="far fa-long-arrow-right"></i> {{ value }}
                </li>
            </ul>
        </template>
        <template v-else>
            <span class="text-muted">
                {{ $t("settings.no_variables_available") }}</span
            >
        </template>
    </div>
</template>


<style lang="scss">
.s-vars {
    li {
        margin-bottom: 10px;
    }
}
</style>