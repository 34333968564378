<script>
import MGConsts from "@/mixins/MGConsts";
import VFilters from "@/components/Search/VFilters";
import InvoiceService from "@/services/InvoiceService";
import VShowInvoice from "@/components/Momentum/Invoices/VShowInvoice";
import AppCfg from "@/mixins/AppCfg";
import Messages from "@/mixins/Messages";

export default {
    name: "VOutbox",
    mixins: [MGConsts, AppCfg, Messages],
    components: {
        VFilters,
        VShowInvoice
    },
    data() {
        return {
            context: null,
            loading: false,
            invoices: [],
            meta: null
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);
        this.apiSearchFilter = this.initSearch();
        this.apiPaginator.sort = "invoice_date";
        this.apiPaginator.direction = "desc";
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        async revokeInvoice(invoice) {
            this.$swal({
                icon: "warning",
                text: this.$t("invoices.revoke_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        const r = await InvoiceService.POST_revokeProjectInvoice(
                            invoice.id
                        );
                        this.$msgSuccess(
                            this.$t("invoices.notify_invoice_has_been_revoked")
                        );
                        this.fetchData();
                    } catch (e) {
                        console.log("revokeInvoice exception", e);
                        this.$handleExceptionError(
                            e,
                            this.$t("invoices.swal_error_cannot_revoke_invoice")
                        );
                    }
                }
            });
        },
        async deleteInvoice(invoice) {
            this.$swal({
                icon: "warning",
                text: this.$t("invoices.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        const r = await InvoiceService.DELETE(invoice.id);
                        await this.fetchData();
                    } catch (e) {
                        if ("errors" in e.response.data) {
                            this.$swal({
                                icon: "error",
                                title: this.$t("error_cannot_delete"),
                                text: e.response.data.errors.join(".\n")
                            });
                        } else {
                            this.$swal({
                                icon: "error",
                                title: this.$t("error_cannot_delete"),
                                text: this.$t("unknown_error")
                            });
                        }
                    }
                }
            });
        },
        showInvoiceDetail(invoice) {
            this.context = invoice;
        },
        /**
         * Init search
         */
        initSearch() {
            return {
                customer: "",
                invoice_type: "",
                invoice_date: "",
                contract_year: ""
            };
        },
        /**
         * Reset serach
         */
        resetSearch() {
            this.$resetSearch();
            this.apiSearchFilter = this.initSearch();
        },
        /**
         * Main fetch data
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["includes"] = "project";
                params["status"] = 1;
                const r = await InvoiceService.GET(params);
                this.invoices = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        }
    }
};
</script>

<template>
    <div class="v-customers-index">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        :search="true"
                        v-model="apiSearchFilter.customer"
                        :search-placeholder="$t('invoices.customer')"
                        @reset-search="resetSearch"
                    >
                        <template slot="default">
                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.invoice_type"
                                    :placeholder="$t('invoices.invoice_type')"
                                    type="select"
                                    :options="mgInvoiceTypes"
                                />
                            </div>

                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.invoice_date"
                                    :placeholder="$t('invoice.invoice_date')"
                                    type="date"
                                />
                            </div>
                        </template>
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="invoices.length">
                            <div class="table-responsive">
                                <v-table
                                    class="table table-mg table-sm table-hover"
                                >
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Customers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.customer")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoice_type"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "invoices.invoice_type"
                                                        )
                                                    }}
                                                </v-sorter>
                                            </v-th>
                                            <v-th>
                                                {{
                                                    $t(
                                                        "invoices.additional_data"
                                                    )
                                                }}
                                            </v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoice_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "invoices.invoice_date"
                                                        )
                                                    }}
                                                </v-sorter>
                                            </v-th>
                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="total_net_amount"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "invoices.net_amount"
                                                        )
                                                    }}
                                                </v-sorter>
                                            </v-th>
                                            <v-th> </v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="invoice in invoices"
                                            :key="invoice.id"
                                        >
                                            <v-td>
                                                {{ invoice.customer.name }}
                                                <small class="text-muted"
                                                    >#{{ invoice.id }}</small
                                                >
                                            </v-td>
                                            <v-td>
                                                {{
                                                    appCfgRead(
                                                        `Invoices.invoice_type.${invoice.invoice_type}`
                                                    )
                                                }}
                                            </v-td>

                                            <v-td>
                                                <!-- Custom data for projects -->
                                                <template
                                                    v-if="
                                                        invoice.invoice_type ==
                                                            'project'
                                                    "
                                                >
                                                    {{ invoice.project.title }}
                                                </template>
                                                <!-- Custom data for contracts -->
                                                <template
                                                    v-if="
                                                        invoice.invoice_type ==
                                                            'contract'
                                                    "
                                                >
                                                    {{
                                                        $t("invoices.seq_num", {
                                                            n: invoice.seq_num,
                                                            of:
                                                                invoice.seq_total
                                                        })
                                                    }}
                                                    <div
                                                        class="small text-muted"
                                                    >
                                                        {{
                                                            $t(
                                                                "invoices.accounting_year"
                                                            )
                                                        }}:
                                                        {{
                                                            invoice.contract_year
                                                        }}
                                                    </div>
                                                </template>
                                                <!-- Custom data for subcriptions -->
                                                <template
                                                    v-if="
                                                        invoice.invoice_type ==
                                                            'subscription'
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "invoices.subscription_period"
                                                        )
                                                    }}:
                                                    {{
                                                        $d(
                                                            new Date(
                                                                invoice.period_from
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $d(
                                                            new Date(
                                                                invoice.period_to
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                    <div
                                                        class="small text-muted"
                                                    >
                                                        {{
                                                            $matchInHash(
                                                                mgPaymentFrequencyHash,
                                                                invoice.frequency
                                                            )
                                                        }}
                                                    </div>
                                                </template>
                                            </v-td>

                                            <v-td>
                                                {{
                                                    $d(
                                                        new Date(
                                                            invoice.invoice_date
                                                        ),
                                                        "short"
                                                    )
                                                }}
                                            </v-td>

                                            <v-td class="text-right">
                                                {{ invoice.total_net_amount }}
                                                {{ invoice.currency }}
                                            </v-td>
                                            <v-td>
                                                <base-dropdown
                                                    titleTag="span"
                                                    :menuOnRight="true"
                                                    :hasToggle="false"
                                                    icon="far fa-ellipsis-h"
                                                >
                                                    <li
                                                        class="dropdown-item"
                                                        @click="
                                                            showInvoiceDetail(
                                                                invoice
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                "invoices.show_detailed_invoice"
                                                            )
                                                        }}
                                                    </li>
                                                    <li
                                                        v-if="
                                                            ACL_RW &&
                                                                invoice.invoice_type !=
                                                                    'project'
                                                        "
                                                        class="dropdown-item text-danger"
                                                        @click="
                                                            deleteInvoice(
                                                                invoice
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                "invoices.delete"
                                                            )
                                                        }}
                                                    </li>
                                                    <li
                                                        v-if="
                                                            ACL_RW &&
                                                                invoice.invoice_type ==
                                                                    'project'
                                                        "
                                                        class="dropdown-item text-danger"
                                                        @click="
                                                            revokeInvoice(
                                                                invoice
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                "invoices.revoke"
                                                            )
                                                        }}
                                                    </li>
                                                </base-dropdown>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-show-invoice
                v-if="context"
                :invoice="context"
                @close="context = null"
            />
        </portal>
    </div>
</template>

<style></style>
