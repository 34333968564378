<script>
import { pick } from "lodash";
import ChargeTemplateService from "@/services/ChargeTemplateService";
import CurrencyService from "@/services/CurrencyService";
import EcService from "@/services/EcService";
import MGConsts from "@/mixins/MGConsts";

import SettingService from "@/services/SettingService";

export default {
    name: "VChargeTemplateForm",

    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    computed: {
        hasInvoicingOptions: function () {
            let check = [
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
            ];

            let show = false;

            check.forEach((value) => {
                if (this.charge[value]) {
                    show = true;
                }
            });

            console.log("Hasinvoice", show);

            return show;
        },
    },
    data() {
        return {
            vars: null, //unused
            showInvoicingOptions: false,
            layouts: null,
            termsOfPayment: null,
            editing: false,
            loading: false,
            errors: null,
            currencies: [],
            ec_products: [],
            charge: {
                id: null,
                economic_product: null,
                name: null,
                invoice_text: null,
                description: null,
                amount: null,
                currency: null,
                invoice_heading: null,
                invoice_text1: null,
                invoice_text2: null,
                invoice_term_of_payment: null,
                invoice_layout: null,
            },
        };
    },
    async created() {
        this.loading = true;
        await this.fetchCurrencies();
        await this.fetchEcProducts();
        await this.fetchEconomicParams();
        this.loading = false;

        if (!this.context.empty) {
            
            this.editing = true;
            this.charge = pick(this.context, [
                "id",
                "name",
                "economic_product",
                "invoice_text",
                "description",
                "amount",
                "currency",
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
            ]);
            console.log("DB", this.charge);
        }
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await ChargeTemplateService.PUT(this.charge);
                } else {
                    const r = await ChargeTemplateService.POST(this.charge);
                }

                this.$emit("close-refresh");
            } catch (e) {
                if (e.response.status == 403) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_no_access"),
                        text: e.response.data.errors.join(".\n"),
                    });
                } else {
                    this.errors = e.response.data.errors;
                }
            }
        },
        /**
         *
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("charge_tpls.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true,
                }).then(async (confirmed) => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await ChargeTemplateService.DELETE(
                                this.charge.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: e.response.data.errors.join(".\n"),
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error"),
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes({
                    fview: "active",
                });
                
                this.currencies = {
                    ... {'': this.$t('none') },
                    ... r.data.data};
                    console.log("CURR", this.currencies);
            } catch (e) {}
        },
        /**
         * Get EC products
         */
        async fetchEcProducts() {
            try {
                const r = await EcService.ecProducts();
                this.ec_products = r.data.data;
            } catch (e) {}
        },
        /**
         * Fetch economic params
         */
        async fetchEconomicParams() {
            try {
                const r = await EcService.ecLayoutsAndTerms();
                this.layouts = r.data.data.layouts;
                this.layouts.unshift({
                    label: this.$t("use_default"),
                    value: "",
                });

                this.termsOfPayment = r.data.data.terms_of_payment;
                this.termsOfPayment.unshift({
                    label: this.$t("use_default"),
                    value: "",
                });
            } catch (e) {}
        },
        /**
         * Fetch settings data + vars
         * UNUSED - but left here, because maybe
         * one day?
         */
        async fetchSettings() {
            try {
                const r2 = await SettingService.getNamespaceVariables(
                    "invoice_templates"
                );
                this.vars = r2.data.data;
            } catch (e) {}
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="subscription-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("charge_tpls.edit_charge_template") }}
            </template>
            <template v-else>
                {{ $t("charge_tpls.create_charge_template") }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <div class="row">
                <div class="col">
                    <div class="alert alert-info alert-outline">
                        {{ $t("charge_tpls.heading_info_text") }}
                    </div>
                </div>
            </div>
            <FormulateForm v-model="charge" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('charge_tpls.name')"
                            :validation-name="$t('charge_tpls.name')"
                            :placeholder="$t('charge_tpls.provide_name')"
                            validation="required"
                            :help="$t('charge_tpls.name_help_text')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="amount"
                            type="currency"
                            :currency="charge.currency"
                            number-style="money"
                            :label="$t('charge.amount')"
                            :placeholder="$t('charge.provide_amount')"
                        />
                    </div>
                    <div class="col">
                        <FormulateInput
                            name="currency"
                            type="select"
                            :options="currencies"
                            :label="$t('charge.currency')"
                            :placeholder="$t('charge.select_currency')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="economic_product"
                            type="select"
                            :options="ec_products"
                            :label="$t('charge.economic_product')"
                            :placeholder="$t('charge.select_economic_product')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="invoice_text"
                            type="textarea"
                            :label="$t('charge.invoice_text')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="description"
                            type="textarea"
                            :label="$t('charge.optional_note')"
                        />
                    </div>
                </div>

                <!-- EXTRA INVOICING OPTIONS START -->
                <!-- Additional invoicing options -->

                <h3 class="sub-heading">
                    {{ $t("charge.additional_invoicing_options") }}
                </h3>

                <div v-show="hasInvoicingOptions || showInvoicingOptions">
                    <div class="row">
                        <div class="col">
                            <FormulateInput
                                name="invoice_heading"
                                type="textarea"
                                :label="$t('charge.custom_invoice_heading')"
                                :help="$t('charge.custom_invoice_heading_help')"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col">
                            <FormulateInput
                                name="invoice_text1"
                                type="textarea"
                                :label="$t('charge.custom_invoice_text1')"
                                :help="$t('charge.custom_invoice_text1_help')"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col">
                            <FormulateInput
                                name="invoice_text2"
                                type="textarea"
                                :label="$t('charge.custom_invoice_text2')"
                                :help="$t('charge.custom_text2_help')"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col">
                            <FormulateInput
                                name="invoice_layout"
                                type="select"
                                :options="layouts"
                                :label="$t('charge.custom_invoice_layout')"
                            />
                        </div>
                        <div class="col">
                            <FormulateInput
                                name="invoice_term_of_payment"
                                type="select"
                                :options="termsOfPayment"
                                :label="$t('charge.custom_term_of_payment')"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    v-show="!hasInvoicingOptions && !showInvoicingOptions"
                >
                    <div class="col">
                        {{
                            $t("charge.additional_invoicing_options_form_text")
                        }}
                    </div>
                    <div class="col text-right">
                        <base-button
                            size="sm"
                            @click="
                                showInvoicingOptions = !showInvoicingOptions
                            "
                        >
                            {{
                                $t("charge.show_additional_invoicing_options")
                            }}</base-button
                        >
                    </div>
                </div>
                <!-- EXTRA INVOICING OPTIONS END -->

                <div class="row mt-4">
                    <div class="col text-left">
                        <base-button
                            v-if="editing"
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>