<script>
export default {
    name: "VInvoiceDetail",
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
};
</script>

<template>
    <div class="v-invoice-detail table-responsive">
        <v-table class="table table-sm">
            <v-tbody>
                <!-- Fixed -->
                <template v-if="invoice.contract_type == 1">
                    <v-tr>
                        <v-td>
                            <template v-if="invoice.using_first_year">
                                {{ $t("invoice.first_year_base_fee") }}
                            </template>

                            <template v-else>
                                {{ $t("invoice.base_fee") }}
                            </template>
                        </v-td>
                        <v-td class="text-right">
                            <template v-if="invoice.using_first_year">
                                {{
                                    $n(invoice.first_year_base_fee, {
                                        style: "currency",
                                        currency: invoice.currency,
                                        currencyDisplay: "code",
                                    })
                                }}
                            </template>
                            <template v-else>
                                {{
                                    $n(invoice.base_fee, {
                                        style: "currency",
                                        currency: invoice.currency,
                                        currencyDisplay: "code",
                                    })
                                }}
                            </template>
                        </v-td>
                    </v-tr>
                    <v-tr v-if="invoice.is_adjusted">
                        <v-td>
                            {{ invoice.adjustment_type_name }} ({{
                                $n(invoice.adjusted_fees.percentage)
                            }}%)
                        </v-td>
                        <v-td class="text-right">
                            {{
                                $n(invoice.adjusted_fees.adjusted_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                    <v-tr class="v-invoice-detail__separator">
                        <v-td>
                            <span class="text-bold">
                                {{
                                    $t("invoice.invoice_numbering", {
                                        n: invoice.invoice_idx,
                                        of: invoice.invoice_total,
                                    })
                                }}</span
                            >
                            <div class="small mt-2">
                                {{
                                    $t("invoice.period_from_to", {
                                        from: $d(
                                            new Date(invoice.from_date),
                                            "short"
                                        ),
                                        to: $d(
                                            new Date(invoice.to_date),
                                            "short"
                                        ),
                                    })
                                }}
                            </div>
                            <div class="small">
                                <template v-if="invoice.aligned">
                                    {{
                                        $t("invoice.used_days", {
                                            n: invoice.period_used,
                                            of: invoice.period_length,
                                        })
                                    }}
                                </template>
                                <template v-else>
                                    <template
                                        v-if="invoice.aligned_base == 'month'"
                                    >
                                        {{
                                            $t("invoice.used_months", {
                                                n: invoice.period_used_m,
                                                of: invoice.period_length_m,
                                            })
                                        }}
                                        <div>
                                            {{ $t("invoice.aligned_base") }}:
                                            {{
                                                $t(
                                                    "invoice.aligned_base_month"
                                                )
                                            }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        {{
                                            $t("invoice.used_days", {
                                                n: invoice.period_used,
                                                of: invoice.period_length,
                                            })
                                        }}
                                        <div>
                                            {{ $t("invoice.aligned_base") }}:
                                            {{
                                                $t(
                                                    "invoice.aligned_base_day"
                                                )
                                            }}
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </v-td>
                        <v-td class="text-right text-bold">
                            {{
                                $n(invoice.final_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                </template>

                <!-- Variable Regulation -->
                <template
                    v-if="
                        invoice.contract_type == 2 &&
                        invoice.type == 'contract_regulation'
                    "
                >
                    <v-tr>
                        <v-td
                            >{{ $t("invoice.actual_revenue") }} ({{
                                invoice.year
                            }})</v-td
                        >
                        <v-td class="text-right">
                            {{
                                $n(invoice.actual, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>

                    <v-tr>
                        <v-td
                            >{{ $t("invoice.factual_calculation") }} ({{
                                invoice.year
                            }})

                            <template v-if="invoice.using_first_year">
                                {{
                                    $t(
                                        "invoice.first_year_percent_turnover_rate"
                                    )
                                }}: {{ $n(invoice.first_year_base_fee_perc) }}%
                            </template>

                            <template v-else>
                                {{ $t("invoice.percent_turnover_rate") }}:
                                {{ $n(invoice.final_perc) }}%
                            </template>
                        </v-td>
                        <v-td class="text-right">
                            {{
                                $n(invoice.calculated, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                    <v-tr>
                        <v-td
                            >{{ $t("invoice.paid_fees") }} ({{
                                invoice.year
                            }})</v-td
                        >
                        <v-td class="text-right">
                            {{
                                $n(invoice.paid, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>

                    <v-tr class="v-invoice-detail__separator">
                        <v-td>
                            <span class="text-bold">{{
                                invoice.document_type
                            }}</span>
                        </v-td>
                        <v-td class="text-right text-bold">
                            {{
                                $n(invoice.final_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                </template>

                <!-- Variable -->
                <template
                    v-if="
                        invoice.contract_type == 2 && invoice.type == 'contract'
                    "
                >
                    <!-- BUDGET -->
                    <v-tr>
                        <v-td
                            >{{ $t("invoice.budgeted") }} ({{
                                invoice.budget_year
                            }})</v-td
                        >
                        <v-td class="text-right">
                            {{
                                $n(invoice.budget, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                    <!-- % rate -->
                    <v-tr>
                        <v-td>
                            <template v-if="invoice.using_first_year">
                                {{
                                    $t(
                                        "invoice.first_year_percent_turnover_rate"
                                    )
                                }}: {{ $n(invoice.first_year_base_fee_perc) }}%
                            </template>

                            <template v-else>
                                {{ $t("invoice.percent_turnover_rate") }}:
                                {{ $n(invoice.base_fee_perc) }}%
                            </template>
                        </v-td>
                        <v-td class="text-right">
                            {{
                                $n(invoice.before_base_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                    <!-- percent regulation? -->
                    <v-tr v-if="invoice.is_adjusted_perc">
                        <v-td>
                            {{ $t("invoice.percent_turnover_rate") }}:
                            {{ $n(invoice.final_perc) }}%<br />
                            {{ invoice.adjustment_type_name }} ({{
                                $n(invoice.adjusted_percs.percentage)
                            }}%)
                        </v-td>
                        <v-td class="text-right">
                            {{
                                $n(invoice.base_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                    <!-- min fee -->
                    <v-tr v-if="invoice.using_min_fee">
                        <v-td>
                            {{ $t("invoice.using_min_fee") }}
                        </v-td>
                        <v-td class="text-right">
                            {{
                                $n(invoice.base_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>

                    <v-tr v-if="invoice.is_adjusted">
                        <v-td>
                            {{ invoice.adjustment_type_name }} ({{
                                $n(invoice.adjusted_fees.percentage)
                            }}%)
                        </v-td>
                        <v-td class="text-right">
                            {{
                                $n(invoice.adjusted_fees.adjusted_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                    <v-tr class="v-invoice-detail__separator">
                        <v-td>
                            <span class="text-bold">
                                {{
                                    $t("invoice.invoice_numbering", {
                                        n: invoice.invoice_idx,
                                        of: invoice.invoice_total,
                                    })
                                }}</span
                            >
                            <div class="small mt-2">
                                {{
                                    $t("invoice.period_from_to", {
                                        from: $d(
                                            new Date(invoice.from_date),
                                            "short"
                                        ),
                                        to: $d(
                                            new Date(invoice.to_date),
                                            "short"
                                        ),
                                    })
                                }}
                            </div>
                            <div class="small">
                                {{
                                    $t("invoice.used_days", {
                                        n: invoice.period_used,
                                        of: invoice.period_length,
                                    })
                                }}
                            </div>
                        </v-td>
                        <v-td class="text-right text-bold">
                            {{
                                $n(invoice.final_fee, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                    </v-tr>
                </template>
            </v-tbody>
        </v-table>

        <pre v-if="false">{{ invoice }}</pre>
    </div>
</template>

<style lang="scss">
.v-invoice-detail {
    &__separator {
        td {
            border-top: 2px solid $gray-400 !important;
        }
    }
}
</style>
